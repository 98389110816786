import * as React from 'react'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'
import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content,
} from '../../components/procedures'
import { TabPanel } from 'react-tabs'

const content = {
  backgroundTitle: 'Other Facial Options',
  subtitle: `Trusted, Experienced & Ethical. Boutique plastic surgery on Sydney's North Shore.`,
  title: 'Ear Correction',
  otherOptions: [
    // {
    //   text: 'Facelift',
    //   to: '/procedures/facelift',
    // },
    {
      text: 'Nose Surgery',
      to: '/procedures/nose-surgery',
    },
    {
      text: 'Ear Correction',
      to: '/procedures/ear-correction',
    },
    {
      text: 'Earlobe Repair',
      to: '/procedures/ear-reconstruction',
    },
    {
      text: 'Eyelid Surgery',
      to: '/procedures/eyelid-surgery',
    },
  ],
}

export default ({ data }) => (
  <MainLayout header="light">
    <Helmet>
      <title>Ear Correction in Sydney - Dr. Graham Sellars</title>
      <meta
        name="description"
        content="An Otoplasty (Ear correction) is the surgery to reduce prominent ears. Choose Dr. Graham Sellars for excellent results."
      />
    </Helmet>

    <Hero bgImage={data.womanWaterfall.childImageSharp} height="600px">
      <h1>{content.title}</h1>
      <p>{content.subtitle}</p>
      <Flex>
        <Button color="#fff" small={true} outline={true}>
          Learn more
        </Button>
      </Flex>
    </Hero>

    <Content>
      <h2>Ear Correction - Otoplasty</h2>
      <p>
        Although there are no functional problems associated with prominent
        ears, the psychological distress it causes can become a problem.
        Changing the angle and shape of the ear through Otoplasty (ear
        correction) makes it appear less obvious.
      </p>
      <p>
        Otoplasty is the surgical procedure performed to reduce prominent ears.
        In children with severe cases of the condition, it is best to perform
        the procedure early, preferably between the ages of 2 to 5 years old.
        Completion at an early age is beneficial in two respects: the cartilage
        is still extremely pliable, permitting greater ease of shaping; second,
        children will experience the psychological benefits from the cosmetic
        improvement. The majority of patients who undergo this operation are
        between 4 and 14 years old. Adults may also benefit from ear correction
        surgery and have the choice to combine it with other facial cosmetic
        procedures.
      </p>

      <h4>Consultation</h4>
      <p>
        We recommend two consultation appointments with the patient (and
        parents, in the case of minors) to discuss concerns, objectives, details
        of the surgery, and any potential risk factors and complications. We
        also use this opportunity to ensure patients have a realistic
        expectation of what we are setting out to do. Dr. Graham Sellars and the
        entire team are committed to making sure you understand as much as
        possible through before and after photos of previous patients,
        supplemental reading material, and individual consultation.
      </p>
      <Link to="/contact/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <BackgroundSection
      image={data.otherBreast.childImageSharp}
      title={content.backgroundTitle}
      height="500px"
      darkness="0.4"
    >
      <p style={{ fontSize: 18, margin: '-1rem 0 2rem' }}>
        Browse Dr. Graham Sellars' full range of surgical and non-surgical
        procedures.
      </p>
      {content.otherOptions.map(({ to, text }) => (
        <Flex mr={2} mb={3} style={{ float: 'left' }}>
          <Link to={to}>
            <Button small={true} color="#fff" outline={true}>
              {text}
            </Button>
          </Link>
        </Flex>
      ))}
    </BackgroundSection>

    <StyledTabs style={{ marginTop: 75 }}>
      <h2>Information about {content.title}</h2>
      <StyledTabList>
        <StyledTab>Surgery</StyledTab>
        <StyledTab>Post operative care</StyledTab>
        <StyledTab>Risks of Surgery</StyledTab>
      </StyledTabList>

      <TabPanel>
        <p>
          The procedure involves cutting the skin behind the ear in an ellipse
          shape to expose the underlying cartilage. We then “score” the
          cartilage to make it more malleable. Although removal of a little
          piece of cartilage is necessary for some, most cases only require
          folding it back into a more natural shape. The wound is then closed
          with a dissolvable suture that doesn’t need to come out.
        </p>
      </TabPanel>
      <TabPanel>
        <p>
          A bandage wrap around the head is necessary immediately after surgery
          to hold the surgical dressings in place. You must keep the wrap in
          place until your first follow-up appointment. Dr. Graham Sellars will
          also prescribe pain relief medication, as you will experience mild to
          moderate discomfort for a couple of days after the procedure. We
          strongly advise patients not to take any medication with aspirin, as
          this can thin the blood and cause bruising and bleeding.
        </p>

        <p>
          Patients can expect to see some swelling and bruising following the
          operation, but these will subside within a week or two. Headbands
          should be worn at night three weeks after dressing removal. Adults may
          return to work and children to school after removal of the dressing
          and bandage. Although this is the case, some tenderness may remain for
          a couple of weeks and patients must refrain from strenuous exercise
          and smoking.
        </p>

        <p>
          Schedule a consultation with Dr. Graham Sellars today to learn more
          about ear corrections and if it is right for you. Call us today at 02
          9480 8577 or fill out the form on our contact page to request an
          appointment.
        </p>
      </TabPanel>
      <TabPanel>
        <h4>Possible risks of any surgery include:</h4>
        <ul>
          <li>Wound infection</li>
          <li>Pain and discomfort</li>
          <li>Nausea</li>
          <li>Haematoma (an accumulation of blood around the surgical site that may require drainage)</li>
          <li>Heavy bleeding from the incisions</li>
          <li>Keloid or hypertrophic scars</li>
          <li>Slow healing</li>
          <li>Separation of the wound edges</li>
          <li>Allergies to anaesthetic agents, antiseptics, suture material or dressings</li>
          <li>Deep vein thrombosis DVT (blood clot in the leg)</li>
        </ul>

        <h4>Specific Ear Correction surgery risks:</h4>
        <ul>
          <li>Asymmetry of the ears. You should not expect both ears to match perfectly as exact symmetry is unlikely and unnatural. Further surgery may be required to correct symmetry problems or irregularities in the cartilage</li>
          <li>Temporary loss of sensation can occur in the skin around the surgical site and surface of the ears.</li>
          <li>Re-protrusion of one or both ears may occur requiring further surgery</li>
        </ul>
      </TabPanel>
    </StyledTabs>

    <BeforeAfter>
      <h2>Before &amp; After</h2>
      <div>
        {data.allFile.edges.map(
          (image) =>
            image.node.childImageSharp && (
              <Img resolutions={image.node.childImageSharp.resolutions} />
            )
        )}
      </div>
      <Flex justifyContent="center">
        <Link to="/gallery">
          <Button inverted={true}>See the full gallery</Button>
        </Link>
      </Flex>
    </BeforeAfter>

    <AboutContact data={data} />
  </MainLayout>
)
export const query = graphql`
  query EarCorrectionQuery {
    allFile(filter: { relativeDirectory: { eq: "gallery/ear" } }) {
      edges {
        node {
          childImageSharp {
            resolutions(width: 500, height: 300, toFormat: JPG) {
              ...GatsbyImageSharpResolutions
            }
          }
        }
      }
    }
    womanWaterfall: file(relativePath: { eq: "background-generic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherBreast: file(relativePath: { eq: "face-close-up.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asaps: file(relativePath: { eq: "ASAPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asps: file(relativePath: { eq: "ASPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "dr-graham-sellars.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fracs: file(relativePath: { eq: "FRACS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
